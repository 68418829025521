<template>
  <div class="container-fluid vh-100 bg-gradient-secondary">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-8 p-1">
          <div class="alert alert-danger" role="alert" v-if="getStatus">
            Esta empresa no esta disponible, por favor contacte a su vendedor
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col p-3 text-center">
          <h3>Seleccione la empresa</h3>
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-for="company in getCompanies"
        :key="company.id"
      >
        <div class="col text-center p-3">
          <button
            type="button"
            @click="check(company.id)"
            class="btn btn-success btn-lg"
          >
            {{ company.name }}</button
          ><br />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  created() {
    if (localStorage.getItem("companiesId")) {
      this.check(localStorage.getItem("companiesId"));
    }
  },
  computed: {
    ...mapGetters(["getCompanies", "getStatus"]),
  },
  methods: {
    ...mapActions({
      check: "checkStatus", // map `this.add()` to `this.$store.dispatch('increment')`
    }),
  },
};
</script>
